<template>

	<el-col :span="24">
		<el-card class="box-card">
			<template #header>
				<div class="card-header">
					<span class="card-title">Steam状态:</span>
					<!-- <el-button class="info" text>刷新</el-button> -->
          <div><el-button type="primary" size="large" @click="open_url('steam://')">唤起Steam</el-button>
          <el-button type="success" size="large" @click="fetchData" :disabled='loading'>刷新</el-button></div>

				</div>
			</template>
			<el-row v-loading="loading" element-loading-text="获取中...">
				<h1>
					<span v-if="api_status === 0" class="status-text-success">当前:已启动✔</span>
					<span v-else-if="api_status === -1" class="status-text-error">当前:获取失败(请先启动Steam后选择刷新)✖</span>
					<span v-else>状态：未知✖</span>
				</h1>
				<el-col :span="24" v-if="api_status === -1"><el-alert title="如果您无需自动获取Unturned路径的话可以忽略该项" type="info" /></el-col>
				
			</el-row>
		</el-card>
	</el-col>

</template>

<script>
	import axios from 'axios'
	import {
		useStore
	} from 'vuex'
	import {
		ElMessage
	} from 'element-plus';
	import {
		computed,
		ref
	} from 'vue'
	import {
		defineComponent,
		onMounted
	} from 'vue';
	export default defineComponent({
		setup() {
			const api_status = ref(-1)
			const loading = ref(false)
			const store = useStore()
			const localport = computed(() => store.state.localport)
			const version = ref("ERROR")
			const fetchData = async () => {
				try {
					loading.value = true
					const response = await axios.get('http://localhost:'+ localport.value +'/api/v2/async_get_steam');
					// console.log(response)
					// 根据接口返回的状态码是否为200，修改变量abc的值
					if (response.status === 200 && response.request.responseText === "true") {

						api_status.value = 0; // 修改变量abc为"正常"
						ElMessage.success('Steam已启动');
					} else {
						api_status.value = -1 // 修改变量abc为"异常"
						// 使用Element Plus的ElMessage组件来弹出消息提示框
						ElMessage.error('未检测到Steam进程，请启动Steam后尝试刷新！');
					}
				} catch (error) {
					api_status.value = -1; // 请求出错时，修改变量abc为"异常"
					// 使用Element Plus的ElMessage组件来弹出消息提示框
					ElMessage.error('检测Steam接口异常，请稍后重试！');
				} finally {
					loading.value = false
				}
			};

      const open_url = async (url,) => {
      try {
        const response = await axios.post('http://localhost:' +localport.value+ '/api/v2/async_open_url', {'url': url,}); // 替换为你的API地址

      } catch (error) {
        console.error(error);
      } finally {

      }
    }

			onMounted(fetchData)

			return {
				fetchData,
				api_status,
				loading,
				open_url
			}
		}
	});
</script>

<style>

</style>