import { createStore } from 'vuex'

export default createStore({
	state: {
		"localport": 8899,
		"unturned_path":"",
	},
	getters: {
	},
	mutations: {
		setLocalPort(state, payload) {
			state.localport = payload;
		},
		setUnturned_path(state, path) {
			state.unturned_path = path;
		},
	},
	actions: {
	},
	modules: {
	}
})