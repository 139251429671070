
	import { defineComponent } from 'vue';
	import Index from '@/components/Index.vue'; // @ is an alias to /src
	export default defineComponent( {
		components: {
		    Index,
		  },
		setup() {
			return {
			}
		}
	});
