import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "common-layout" }
const _hoisted_2 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_ApiStatusCard = _resolveComponent("ApiStatusCard")!
  const _component_CurrentVersion = _resolveComponent("CurrentVersion")!
  const _component_LatestVersion = _resolveComponent("LatestVersion")!
  const _component_CallMe = _resolveComponent("CallMe")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_SteamStatus = _resolveComponent("SteamStatus")!
  const _component_UnturnedPath = _resolveComponent("UnturnedPath")!
  const _component_Budinglist = _resolveComponent("Budinglist")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_main, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_el_card, {
                        class: "box-card logo-img",
                        style: {"height":"300px"}
                      })
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ApiStatusCard),
            _createVNode(_component_el_row, { gutter: 20 }, {
              default: _withCtx(() => [
                _createVNode(_component_CurrentVersion),
                _createVNode(_component_LatestVersion),
                _createVNode(_component_CallMe)
              ]),
              _: 1
            }),
            _createVNode(_component_el_divider),
            _createVNode(_component_el_row, { gutter: 20 }, {
              default: _withCtx(() => [
                _createVNode(_component_SteamStatus),
                _createVNode(_component_el_divider),
                _createVNode(_component_UnturnedPath),
                _createVNode(_component_el_divider),
                _createVNode(_component_Budinglist)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}