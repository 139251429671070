<template>
	<el-col :span="8">
		<el-card class="box-card">
			<template #header>
				<div class="card-header">
					<span class="card-title">当前版本:</span>
					<el-button type="success" size="large" @click="fetchData" :disabled='loading'>刷新</el-button>
				</div>
			</template>
			<el-row v-loading="loading" element-loading-text="获取中...">
				<h1>
					<span v-if="api_status === 0" class="status-text-success">当前版本:v{{version}}</span>
					<span v-else-if="api_status === -1" class="status-text-error">接口异常✖</span>
					<span v-else>接口异常✖</span>
				</h1>
			</el-row>
		</el-card>
	</el-col>
</template>

<script>
	import axios from 'axios'
	import {
		useStore
	} from 'vuex'
	import {
		ElMessage
	} from 'element-plus';
	import {
		computed,
		ref
	} from 'vue'
	import {
		defineComponent,
		onMounted
	} from 'vue';
	export default defineComponent({
		setup() {
			const api_status = ref(-1)
			const loading = ref(false)
			const store = useStore()
			const localport = computed(() => store.state.localport)
			const version = ref("ERROR")
			const fetchData = async () => {
				try {
					loading.value = true
					const response = await axios.get('http://localhost:' + localport.value + '/api/v2/get_version');
					// console.log(response)
					// 根据接口返回的状态码是否为200，修改变量abc的值
					if (response.status === 200) {
						api_status.value = 0; // 修改变量abc为"正常"
						ElMessage.success('获取软件版本成功!');
						version.value = response.request.responseText
						// console.log(version.value)
					} else {
						api_status.value = -1 // 修改变量abc为"异常"
						// 使用Element Plus的ElMessage组件来弹出消息提示框
						ElMessage.error('错误:无法获取当前软件版本！');
					}
				} catch (error) {
					api_status.value = -1; // 请求出错时，修改变量abc为"异常"
					// 使用Element Plus的ElMessage组件来弹出消息提示框
					ElMessage.error('错误:无法获取当前软件版本！');
				} finally {
					loading.value = false
				}
			};

			onMounted(fetchData)

			return {
				fetchData,
				api_status,
				loading,
				version
			}
		}
	});
</script>

<style>

</style>