
import {computed, defineComponent, onBeforeUnmount, onMounted, ref} from 'vue'
import axios from 'axios'
import {useStore} from 'vuex'
// import { Timer } from '@element-plus/icons-vue'
import {ElMessage, ElMessageBox} from 'element-plus'

export default defineComponent({
  setup() {

    const loading = ref(false)
    const is_install = ref(true)
    const store = useStore()
    const localport = computed(() => store.state.localport)
    const install_info = ref({'code': 0, 'percentage': 0, 'message': '等待安装'});
    const unturned_path = ref('未设定目录');
    const tableData: Bd[] = [
      {
        id: '-1',
        date: '无数据',
        name: '无数据',
        author: '无数据',
      }
    ]
    const new_tableData = ref(tableData)
    // console.log("tableData",tableData)
    // console.log("new_tableData",new_tableData.value)

    interface Bd {
      id: string
      name: string
      date: string
      author: string
    }

    const search = ref('')

    const filterTableData = computed(() =>
        new_tableData.value.filter(
            (data) =>
                !search.value ||
                data.name.toLowerCase().includes(search.value.toLowerCase())
        )
    )
    const handleEdit = (index: number, row: Bd) => {
      // console.log(index, row)

    }
    const handleinstall = (index: number, row: Bd) => {
      // console.log(index, row)
      get_unturned_path()
      setTimeout(() => {
        install(index, row);
      }, 500);
    }


    const get_mod_data = async () => {
      try {
        loading.value = true
        // 在 API 调用前延迟 5 秒
        // await new Promise(resolve => setTimeout(resolve, 1000)); // 5000 毫秒即 5 秒

        const response = await axios.get('http://localhost:' + localport.value + '/api/v2/async_get_mod_data');

        // 根据接口返回的状态码是否为 200，修改变量 unturned_path 的值
        if (response.status === 200) {

          // new_tableData.value = response.data;
          new_tableData.value = response.data
          // console.log(new_tableData)

          ElMessage.success('获取补丁信息成功！');
        } else {

          ElMessage.error('获取补丁信息失败！');
          new_tableData.value = tableData
          // 使用 Element Plus 的 ElMessage 组件来弹出消息提示框
          // ...
        }
      } catch (error) {
        console.log(error)
        ElMessage.error('获取补丁接口请求出错！');
        new_tableData.value = tableData
        // 使用 Element Plus 的 ElMessage 组件来弹出消息提示框
        // ...
      } finally {
        loading.value = false
      }
    };


    const install = (index: number, row: Bd) => {
      ElMessageBox.confirm(
          '你是否要安装汉化补丁 ' + row.name + ' ? 补丁将安装至【 ' + unturned_path.value + "】",
          '提示', {
            confirmButtonText: '安装',
            cancelButtonText: '取消',
            type: 'warning',
          }
      )
          .then(() => {
            // 检查路径下是否存在un
            check_unturnd().then(result => {
              // console.log("结果", result)
              if (result === true) {
                // 处理请求成功的情况
                restart_install_info()
                fc2(index)
              } else {
                // 处理请求失败的情况
                ElMessage.error('未能在路径' + unturned_path.value + '下找到unturned.exe，请检查路径是否正确');
              }
            }).catch(error => {
              // 处理请求出错的情况
              console.error('请求出错', error);
              ElMessage.error('请求出错');
            });

          })
          .catch(() => {

          })
    }

    const uninstall = () => {
      ElMessageBox.confirm(
          '你是否要在目录【 ' + unturned_path.value + "】下清除汉化补丁吗？",
          '提示', {
            confirmButtonText: '卸载',
            cancelButtonText: '取消',
            type: 'warning',
          }
      )
          .then(() => {
            // 检查路径下是否存在un
            check_unturnd().then(result => {
              // console.log("结果", result)
              if (result === true) {
                // 处理请求成功的情况
                get_unturned_path()
                setTimeout(() => {
                  uninstall_bd();
                }, 500);

              } else {
                // 处理请求失败的情况
                ElMessage.error('未能在路径' + unturned_path.value + '下找到unturned.exe，请检查路径是否正确');
              }
            }).catch(error => {
              // 处理请求出错的情况
              console.error('请求出错', error);
              ElMessage.error('请求出错');
            });

          })
          .catch(() => {

          })
    }


    const fc2 = async (index: number,) => {
      try {
        // console.log(index)
        is_install.value = false
        const response = await axios.post('http://localhost:' + localport.value + '/api/v2/async_install_buding', {'id': index}); // 替换为你的API地址

      } catch (error) {
        console.error(error);
      } finally {

      }
    };

    const restart_install_info = async () => {
      try {
        const response = await axios.get('http://localhost:' + localport.value + '/api/v2/async_restart_install_info'); // 替换为你的API地址
      } catch (error) {

      } finally {

      }
    };

    const progressStatus = computed(() => {
      return install_info.value.code === 2 ? 'success' : ''
    })

    const infoStatus = computed(() => {

      return install_info.value.code === 2 ? true : false
    })

    const fc3 = async () => {
      try {
        const response = await axios.get('http://localhost:' + localport.value + '/api/v2/async_get_install_info'); // 替换为你的API地址
        install_info.value.code = response.data.code;
        install_info.value.percentage = response.data.percentage;
        install_info.value.message = response.data.message;

        // console.log(install_info)
      } catch (error) {
        console.error(error);
      } finally {

      }
    };

    const check_unturnd = async () => {
      try {
        const response = await axios.get('http://localhost:' + localport.value + '/api/v2/async_check_unturnd'); // 替换为你的API地址
        if (response.status === 200 && response.request.response === 'true') {
          return true
        } else {
          return false
        }

        // console.log(install_info)
      } catch (error) {
        console.error(error);
        return false
      } finally {

      }
    };


    const get_unturned_path = () => {
      return axios.get('http://localhost:' + localport.value + '/api/v2/async_get_unturnd_path')
          .then(response => {
            if (response.status === 200) {
              unturned_path.value = response.data.data;
              ElMessage.success('获取路径成功！');
            }
          })
          .catch(error => {
            console.error(error);
          });
    };

    const uninstall_bd = () => {
      return axios.get('http://localhost:' + localport.value + '/api/v2/async_uninstall_bd')
          .then(response => {
            if (response.status === 200 && response.request.response === '200') {
              ElMessage.success('清理完成！');
              ElMessageBox.alert(
              '清理完成',
              '完成',
              {
                confirmButtonText: '关闭',
                cancelButtonText: 'Cancel',
                type: 'success',
              }
          )
            }
          })
          .catch(error => {
            ElMessage.error('清理时出错！');
            ElMessageBox.alert(
              '清理时出错',
              '失败',
              {
                confirmButtonText: '关闭',
                cancelButtonText: 'Cancel',
                type: 'error',
              }
          )
          });
    };


    onMounted(() => {
      get_mod_data();
      get_unturned_path();
      // fc3();
    });

    const intervalId = setInterval(fc3, 1000);
    onBeforeUnmount(() => {
      clearInterval(intervalId);
    });

    return {
      loading,
      search,
      new_tableData,
      tableData,
      handleEdit,
      handleinstall,
      filterTableData,
      get_mod_data,
      is_install,
      install_info,
      progressStatus,
      infoStatus,
      get_unturned_path,
      restart_install_info,
      uninstall
    }
  }
});
