
	import { computed, ref } from 'vue'
	import { defineComponent, onMounted } from 'vue';
	import { Timer } from '@element-plus/icons-vue'
	import ApiStatusCard from '@/components/ApiStatusCard.vue';
	import UnturnedPath from '@/components/UnturnedPath.vue';
	import CurrentVersion from '@/components/CurrentVersion.vue';
	import LatestVersion from '@/components/LatestVersion.vue';
	import SteamStatus from '@/components/SteamStatus.vue';
	import CallMe from '@/components/CallMe.vue';
	import Budinglist from '@/components/Budinglist.vue';
	import { useStore } from 'vuex'


	export default defineComponent({
		components: {
			ApiStatusCard,
			UnturnedPath,
			CurrentVersion,
			LatestVersion,
			CallMe,
			SteamStatus,
			Budinglist
		},

		setup() {
			const store = useStore()
			const localport = computed(() => store.state.localport)
			
			// 重设端口号
			// 获取指定参数的值
			const searchParams = new URLSearchParams(window.location.search);
			const new_localport = ref<number>(parseInt(searchParams.get('new_localport')!) || localport.value);
			// console.log(new_localport)
			// 同步到vuex
			store.commit("setLocalPort",new_localport)
			// console.log(computed(() => store.state.localport))

			// 将 ref 暴露给模板
			return {
			}
		}

	});
