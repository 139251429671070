<template>
	<el-row :gutter="20">
		<el-col :span="24">
			<el-card class="box-card">
				<template #header>
					<div class="card-header">
						<span class="card-title">API状态:</span>
						<!-- <el-button class="info" text>刷新</el-button> -->
						<el-button type="success" size="large" @click="fetchData" :disabled='loading'>刷新</el-button>
					</div>
				</template>
				<el-row v-loading="loading" element-loading-text="获取中...">
					<h1>
						<span v-if="api_status === 0" class="status-text-success">接口状态：正常✔</span>
						<span v-else-if="api_status === -1" class="status-text-error">接口状态：异常✖</span>
						<span v-else>接口状态：未知✖</span>
					</h1>
				</el-row>
			</el-card>
		</el-col>
	</el-row>
</template>

<script>
	import axios from 'axios'
	
	import {
		ElMessage
	} from 'element-plus';
	import {
		computed,
		ref
	} from 'vue'
	import {
		defineComponent,
		onMounted
	} from 'vue';
	export default defineComponent({
		setup() {
			const api_status = ref(-1)
			const loading = ref(false)

			const fetchData = async () => {
				try {
					loading.value = true
					const response = await axios.get('http://cnapi.unbbs.net/api/dyapi');
					// console.log(response)
					// 根据接口返回的状态码是否为200，修改变量abc的值
					if (response.status === 200) {
						api_status.value = 0; // 修改变量abc为"正常"
						ElMessage.success('链接到Api服务器正常!');
					} else {
						api_status.value = -1 // 修改变量abc为"异常"
						// 使用Element Plus的ElMessage组件来弹出消息提示框
						ElMessage.error('接口异常，请稍后重试！');
					}
				} catch (error) {
					api_status.value = -1; // 请求出错时，修改变量abc为"异常"
					// 使用Element Plus的ElMessage组件来弹出消息提示框
					ElMessage.error('接口异常，请稍后重试！');
				} finally {
					loading.value = false
				}
			};

			onMounted(fetchData)
			
			return {
				fetchData,
				api_status,
				loading
			}
		}
	});
</script>

<style>

</style>