<template>
  <el-col :span="24">
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <span class="card-title">Unturnd游戏路径:</span>
          <!-- <el-button class="info" text>刷新</el-button> -->
          <div>
            <el-button type="success" size="large" @click="dialogVisible = true" :disabled='loading'>尝试自动获取
            </el-button>
          </div>


        </div>
      </template>
      <el-row v-loading="loading" element-loading-text="获取中...">
        <el-col :span="24">
          <el-alert title="如果自动获取失效,您可以手动填写地址并保存" type="success"/>
          <br></el-col>
        <el-col :span="22">
          <el-input v-model="unturned_path" placeholder="请输入 Unturned 的游戏路径"/>
        </el-col>
        <el-col :span="2" style="text-align:right;">
          <el-button type="success" @click="Set_unturned_path">保存</el-button>
        </el-col>

      </el-row>
    </el-card>
  </el-col>

  <el-dialog v-model="dialogVisible" title="提示" width="50%" :before-close="handleClose">
		<span>我们将尝试通过 Steam 启动 Unturned 游戏本体来进行自动获取游戏路径<br>
		在此期间可能会多次启动Unturned以校验路径<br>
		在获取完成后Unturned将被自动关闭<br>
		如果您同意,请选择 &gt;我知道了&lt; 
		<br>如果您不同意请选择 &gt;取消&lt;
		<br><el-tag class="ml-2" type="warning">如果自动获取失效，您可以手动填写Unturned路径并保存</el-tag></span>
    <template #footer>
			<span class="dialog-footer">
				<el-button @click="dialogVisible = false">取消</el-button>
				<el-button type="primary" @click="fetchData();setdialogVisiblefalse()">
					我知道了
				</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script>
import axios from 'axios'
import {computed, defineComponent, ref} from 'vue'
import {ElMessage, ElMessageBox} from 'element-plus'
import {useStore} from 'vuex'

export default defineComponent({
  setup() {
    const unturned_path = ref('')
    const loading = ref(false)
    const dialogVisible = ref(false)
    const store = useStore()
    let is_steam = false
    const localport = computed(() => store.state.localport)
    const handleClose = (done) => {
      // ElMessageBox.confirm('你确定要关闭吗?')
      //     .then(() => {
      //       // 处理确认关闭对话框的逻辑
      //       done();
      //     })
      //     .catch(() => {
      //       // 处理取消关闭对话框的逻辑
      //     });
      done();
    };

    const setdialogVisiblefalse = () => {

      dialogVisible.value = false
    }


    const find_steam = async () => {
      try {
        loading.value = true
        const response = await axios.get('http://localhost:' + localport.value + '/api/v2/async_get_steam');
        // console.log(response)
        // 根据接口返回的状态码是否为200，修改变量abc的值
        if (response.status === 200 && response.request.responseText === "true") {
          is_steam = true
        } else {
          is_steam = false
        }
      } catch (error) {
        is_steam = false
      }
    };

    // find_steam().then(result)
    // console.log(result)

    const fetchData = async () => {
      loading.value = true
      await find_steam()
      console.log(is_steam)
      if (is_steam === true) {
        try {

          // 在 API 调用前延迟 5 秒
          // await new Promise(resolve => setTimeout(resolve, 1000)); // 5000 毫秒即 5 秒

          const response = await axios.get('http://localhost:' + localport.value + '/api/v2/async_find_get_unturned_path');

          // 根据接口返回的状态码是否为 200，修改变量 unturned_path 的值
          if (response.status === 200) {
            // console.log(response)
            unturned_path.value = response.data.data; // 修改变量 unturned_path 为"正常"
            // store.commit('setUnturned_path', unturned_path.value)
            ElMessage.success('获取Unturned路径成功！');
            ElMessageBox.alert(
                '获取到的路径为:' + unturned_path.value,
                '成功',
                {
                  confirmButtonText: '关闭',
                  cancelButtonText: 'Cancel',
                  type: 'success',
                }
            )
          } else {
            // unturned_path.value = "";
            ElMessage.error('获取Unturned路径失败！');
            ElMessageBox.alert(
                '获取Unturned路径失败',
                '失败',
                {
                  confirmButtonText: '关闭',
                  cancelButtonText: 'Cancel',
                  type: 'error',
                }
            )
            // 使用 Element Plus 的 ElMessage 组件来弹出消息提示框
            // ...
          }
        } catch (error) {
          console.log(error)
          // unturned_path.value = "请求出错!"; // 请求出错时，修改变量 unturned_path 为"异常"
          ElMessage.error('获取Unturned接口请求出错！');
          ElMessageBox.alert(
              '获取Unturned接口请求出错',
              '失败',
              {
                confirmButtonText: '关闭',
                cancelButtonText: 'Cancel',
                type: 'error',
              }
          )
          // 使用 Element Plus 的 ElMessage 组件来弹出消息提示框
          // ...
        } finally {
          loading.value = false
        }
      } else {
        open_alera()
      }
      loading.value = false
    };

    const Set_unturned_path = async () => {
      try {

        // 在 API 调用前延迟 5 秒
        // await new Promise(resolve => setTimeout(resolve, 1000)); // 5000 毫秒即 5 秒

        const response = await axios.post('http://localhost:' + localport.value + '/api/v2/async_set_unturnd_path', {"path": unturned_path.value});

        // 根据接口返回的状态码是否为 200，修改变量 unturned_path 的值
        if (response.status === 200 && response.request.response === "200") {
          ElMessage.success('设置Unturned路径成功！');
        } else {
          ElMessage.error('设置Unturned路径失败！');
          // 使用 Element Plus 的 ElMessage 组件来弹出消息提示框
          // ...
        }
      } catch (error) {
        console.log(error)
        ElMessage.error('设置Unturned接口请求出错！');
        // 使用 Element Plus 的 ElMessage 组件来弹出消息提示框
        // ...
      } finally {

      }

    };

    const open_alera = () => {
      ElMessageBox.alert(
          '请先保证Steam已启动',
          '错误',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning',
          }
      )
    }

    return {
      unturned_path,
      fetchData,
      loading,
      handleClose,
      dialogVisible,
      setdialogVisiblefalse,
      Set_unturned_path
    }
  }
});
</script>

<style scoped>
.dialog-footer button:first-child {
  margin-right: 10px;
}
</style>