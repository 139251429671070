<template>
  <el-col :span="8">
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <span class="card-title">联系我们:</span>
          <!-- <el-button type="success" size="large" @click="fetchData" :disabled='loading'>刷新</el-button> -->
        </div>
      </template>
      <el-row :gutter="20" style="text-align: center;" justify="space-between">

        <el-col :span="12">
          <h1><span class="status-text-success"><el-button type="primary"
                                                           size="large" @click="open_url('https://www.unbbs.net/1099.html')">访问论坛</el-button></span>
          </h1>
        </el-col>
        <el-col :span="12">
          <h1><span class="status-text-success"><el-button type="primary"
                                                           size="large" @click="open_url('https://cnapi.unbbs.net/')">访问汉化页</el-button></span></h1>
        </el-col>
      </el-row>
    </el-card>
  </el-col>
</template>

<script>
import axios from 'axios'
import {defineComponent,computed} from 'vue'
import {useStore} from 'vuex'

export default defineComponent({
  setup() {
    const store = useStore()
    const localport = computed(() => store.state.localport)

    const open_url = async (url,) => {
      try {
        const response = await axios.post('http://localhost:' +localport.value+ '/api/v2/async_open_url', {'url': url,}); // 替换为你的API地址

      } catch (error) {
        console.error(error);
      } finally {

      }
    }

    return {
      open_url
    }
  }
});
</script>

<style>
</style>